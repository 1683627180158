<template>

<div>




   
       <Navbar />



   <div class="section bg-slate   autospacing  ">
     <div class=" w-container pt-8">





       <section class="hero circuit-overlay  text-center ">
          <div class="flex flex-col lg:w-1/2" style="margin:0 auto">

          <div class=" text-center  "  >
            <span class="title font-roboto text-white font-bold text-4xl">
                0xPool
            </span>
            </div>

            <div  v-if="false"  class="loading-animation">
                <div class="loader"></div>
            </div> 

                <p class="text-white font-bold">Mining URL United States: http://us.0xpool.io:80 <br>
            Mining URL Europe: http://eu.0xpool.io:80 <br>

                <div class="whitespace-md"></div>

                <div class="account-search-container ">
                  <div class="field">
                    <div class="label text-white font-bold">View Mining Account</div>
                    <div class="control">
                      <form v-on:submit.prevent="submitMiningAccountSearch">
   
                       <input v-model="miningAccountSearchQuery" class="input dark-input" :class="validateAddress == true? 'invalid':''" type="text" placeholder="0x...">
                      </form>
                    <p class="errormsg" v-show="validateAddress">Please enter valid Mining Address</p>
                    </div>
                  </div>
                </div>
            </div>

                <br>
            <div class="w-full p-4 m-4">
                <div class="m-2 text-lg text-white"> BNBTC Pool Status</div>
                <div v-if="poolStatus.bsc.poolStatus === 'active'" >
                    <div class="bg-green-500 w-full p-2">
                      BNBTC Mining Pool is Active
                    </div>

                 </div>

                 <div v-if="poolStatus.bsc.poolStatus !== 'active'" >
                   <div class="bg-yellow-500 w-full p-2">
                        <div class="text-md">BNBTC Mining Pool is Suspended</div>
                         <div class="text-sm"> [{{poolStatus.bsc.suspensionReason}}] </div>
                   </div>

                 </div>

              </div>
            <div class="w-full p-4 m-4">
           <div class="m-2 text-lg text-white">ETI Pool Status</div>
           <div v-if="poolStatus.etica.poolStatus === 'active'" >
             <div class="bg-green-500 w-full p-2">
               ETI Mining Pool is Active
             </div>

           </div>

           <div v-if="poolStatus.etica.poolStatus !== 'active'" >
             <div class="bg-yellow-500 w-full p-2">
               <div class="text-md">ETI Mining Pool is Suspended</div>
               <div class="text-sm"> [{{poolStatus.etica.suspensionReason}}] </div>
             </div>

           </div>

         </div>
       </section>


       <section id="news" class="box background-primary text-center ">
         <div class='text-lg text-white'><p>email contact: <a href="mailto:admin@0xpool.me">admin@0xpool.me</a></p></div>
       <div class='text-xl font-bold text-white'> News </div>

         <div class="inline-block p-2 text-white">
           <div class="text-sm">{{ formatDate('2024-09-16') }}</div>
           <div class="text-lg text-white font-bold">Guardian Hardfork: ETI Mining Update</div>
           <p>
             The Guardian Hardfork has successfully occurred. As a result, ETI mining is now exclusively available with RandomX miners, such as <a href="https://xmrig.com/download" target="_blank">xmrig</a> and <a href="https://github.com/doktor83/SRBMiner-Multi/releases/" target="_blank">SRBMiner</a>.
             <br><br>
             All miners who were previously mining using the SoliditySHA3 algorithm have been automatically switched over to BNBTC mining.
             <br><br>
             Stratum servers for ETI (and BNBTC if using tt-miner) remain the same:
             <ul>
               <li>United States: <code>us.0xpool.io:3333</code></li>
               <li>Europe: <code>eu.0xpool.io:3333</code></li>
             </ul>
             <br><br>
             Please note: Only use your ethereum address as login name.<br>
             your password configured in the worker configuration will be used as worker name.
           </p>
         </div>


         <!-- New News Item: Infrastructure Changes for ETI to RandomX Transition -->
         <div class="inline-block p-2 text-white">
           <div class="text-sm">{{ formatDate('2024-08-31') }}</div>
           <div class="text-lg text-white font-bold">Important Infrastructure Changes for ETI to RandomX Transition</div>
           <p>
             In preparation for the algorithm change of ETI to RandomX, we are making significant infrastructure updates.
             <br><br>
             <strong>Action Required:</strong> Miners currently using <code>stratum.0xpool.io</code> or <code>stratum.eu.0xpool.io</code> should change the server URL to <code>0xpool.io:3333</code> or <code>eu.0xpool.io:3333</code> respectively.
             <br><br>
             After the transition to RandomX, ETI mining will only be possible via Stratum. Miners using the previous protocol on <code>0xpool.io:80</code> or <code>eu.0xpool.io:80</code> do not need to change anything and will be automatically moved to the new infrastructure.
             <br><br>
             Once the transition to RandomX is completed, all miners still connected and mining the SoliditySHA3 algorithm will automatically switch to mining BNBTC tokens.
           </p>
         </div>

         <div class="inline-block p-2 text-white">
           <div class="text-sm">{{ formatDate('2024-03-18') }}</div>
           <div class="text-lg text-white font-bold">Gain More Control Over Your ETI Earnings!</div>
           <p>Attention, miners! <br>
               You now have the ability to set your own payout thresholds for ETI on your profile page. <br>
               With this new level of customization, manage your mining rewards to suit your financial strategies. <br>
               Log in to your account and take control today! <br>
               Plus, stay informed with our new "Tokens Earned Last 24h" statistic, <br>
               providing you with up-to-date insights on your mining performance. </p>
         </div>


         <!-- Combined New News Item: Stratum Support and TT-Miner Release -->
         <div class="inline-block p-2 text-white">
           <div class="text-sm">{{ formatDate('2024-02-15') }}</div>
           <div class="text-lg text-white font-bold">Stratum Support and TT-Miner Version 2024.1.1 Release</div>
           <p>We're excited to announce the addition of stratum support with our mining servers now available at
             <br>
             <code>stratum.0xpool.io:80</code> and <code>stratum.eu.0xpool.io:80</code>. <br>
             <br>
             This update significantly enhances mining efficiency and stability. <br>
             <br>
             In line with this upgrade, we also encourage all GPU miners to download and switch to the newly released TT-Miner Version 2024.1.0, which supports stratum communication with the pool for improved performance. <br>
             Get the latest version at <a href="https://github.com/TrailingStop/TT-Miner-release" target="_blank">https://github.com/TrailingStop/TT-Miner-release</a>.<br>
             <br>
             Please note that this stratum implementation is NOT compatible with Blackminer FPGA or any existing stratum implementation.<br>
             For details about this implementation and information for FPGA developers on how to integrate it with their machines, please send an email to the pool administrator.
           </p>
         </div>

         <!-- New News Item: Asia Pacific Server Closure -->
         <div class="inline-block p-2 text-white">
           <div class="text-sm">{{ formatDate('2024-01-30') }}</div>
           <div class="text-lg text-white font-bold">Closure of Asia Pacific Server</div>
           <p>Due to low demand, we have closed the Asia Pacific server. All requests are now redirected to the US server. We encourage Asian users to keep the Asia Pacific mining URL configured, as we may reintroduce the server with increasing demand.</p>
         </div>

         <div class="inline-block p-2 text-white">
           <div class="text-sm">{{ formatDate('2023-12-03') }}</div>
           <div class="text-lg text-white font-bold">Global Expansion: New Servers in Germany and Singapore!</div>
           <p></p>
           <p>
             <span class="flag-icon flag-icon-globe"></span> <strong>Great News for the 0xpool.io Mining Community!</strong> <span class="flag-icon flag-icon-globe"></span> <br>
             We are thrilled to announce significant expansions to our global network! New servers in Europe and Asia are now online to provide a more efficient and responsive mining experience for our international community. <br>
             <br>
             <span class="flag-icon flag-icon-de"></span> <strong>European Server:</strong> <a href="https://eu.0xpool.io">eu.0xpool.io</a> <br>
             Join our new server in Germany, specially set up for our European miners. Expect lower latency and enhanced connection speeds, making your mining activities more efficient than ever. <br>
             <br>
             <span class="flag-icon flag-icon-sg"></span> <strong>Asia-Pacific Server:</strong> <a href="https://ap.0xpool.io">ap.0xpool.io</a> <br>
             We're excited to introduce our server in Singapore, dedicated to serving the Asia-Pacific region. This addition aims to provide an optimized mining experience with faster and more reliable connections. <br>
             <br>
             <span class="flag-icon flag-icon-us"></span> <strong>Continued Service in the US:</strong> <a href="https://0xpool.io">0xpool.io</a> or <a href="https://us.0xpool.io">us.0xpool.io</a><br>
             Our US server remains operational, continuing to provide robust and reliable service to our miners in the United States. <br>
             <br>
             With these new servers in Germany and Singapore, along with our existing US server, we're bringing you closer to a seamless global mining network. Whether you're in Europe, Asia-Pacific, or the Americas, you're always connected to the best possible mining node. <br>
             <br>
             Get started with our new servers at <a href="https://eu.0xpool.io">eu.0xpool.io</a> and <a href="https://ap.0xpool.io">ap.0xpool.io</a> and feel the difference in your mining experience! <br>
             <br>
             Thank you for your continued support of 0xpool.io. Your engagement and feedback are what drive us to keep innovating and improving. <br>
             <br>
             Happy Mining! 💻⛏️
           </p>
         </div>

         <div class="inline-block p-2 text-white">
           <div class="text-sm">{{ formatDate('2023-11-22') }}</div>
           <div class="text-lg text-white font-bold">New Feature: Display Individual Worker Names and Hashrates</div>
           <p></p>
           <p>
             We're excited to announce a new feature for our miners! <br>
             You can now view individual worker names and their hash rates on the 0xPool. <br>
             Simply add <code>.workername</code> to your wallet address in the miner configuration, and you'll see detailed statistics for each of your workers. <br>
             This enhancement allows for better tracking and management of your mining activity.  <br>
             <br>
             Try it out today!
           </p>
         </div>

         <br><br>

         <div class="inline-block p-2 text-white">
           <div class="text-sm">{{ formatDate('2023-11-14') }}</div>
           <div class="text-lg text-white font-bold">Important Update: Changes to Mining Options</div>
           <p></p>
           <p>
             Please note that we have removed 0xBTC from our mining options due to a critical bug in the contract, which has halted its mining capabilities. <br>
             To continue providing our users with valuable mining opportunities, we have added ETI as a new mining option. <br>
             We encourage our miners to explore the potential of ETI mining and apologize for any inconvenience caused by this change.
           </p>
         </div>

         <br><br>

         <div class= "inline-block p-2 text-white">
           <div class="text-sm">{{ formatDate('2022-01-16') }}</div>
           <div class="text-lg text-white font-bold">0xPool released</div>
           <p></p>
           <p>This pool is now proudly serving both BNBTC <em>and</em> 0xBTC mining.<br>
             Select your favorite currency on your accounts page,<br>
             and if mining is suspended for any reason, <br>
             enjoy automatic switchover to the other currency.</p>


         </div>

         <br><br>

         <div class= " inline-block p-2 text-white">
           <div class="text-sm">{{ formatDate('2021-10-01') }}</div>
           <div class="text-lg text-white font-bold">COMMUNITY FEE INTRODUCED</div>
           <p></p>
           <p>We are happy to announce that the pool now allows<br>
           the miners to opt-in to a self-choosen community fee<br>
           to contribute to further BNBTC developments.</p>
           <p>You can set it on your accounts page.</p>

           <p>The percentage you set will be deducted on payout<br>
           and added to the <a href="https://0xpool.io/profile/0x2ccd2fb3de03fa100208f6b0a6f6e75fc8853238" target="_blank">BNBTC development funds</a> account.<br>
           </p>
         </div>

       </section>









   <section id="guide" class="box background-primary text-center ">
   <div class='text-lg text-white'> Start Mining</div>

         <div @click="showInstructions=!showInstructions" class="cursor-pointer   select-none bg-gray-800 p-1 mt-1 rounded text-white text-xs inline-block hover:bg-gray-700"> Instructions</div>

          <br>
      
        <div class="  m-2 "  v-if="showInstructions">
             
            <div class= " inline-block bg-gray-800 p-2 text-white">
              <p>GPU Mining is not recommended anymore!</p>
              <p>Please consider using FPGA hardware </p>
              <hr>
              <p>Download the mining software</p>
              <hr>
              <p>Set pool URL to 'http://0xpool.me:80'</p>
              <hr>
              <p>Set address to your wallet address and begin mining!</p>
              <p> --- NO PRIVATE KEY REQUIRED FOR POOL MINING --- </p>
                
             </div>



            
     </div>
      <div class="whitespace-sm"></div>
        
      

        <a href="https://github.com/Mineions/BNBTCminer/releases" target="_blank">
          <div class='bg-purple-500 p-4 mt-4 rounded text-black inline-block hover:bg-purple-400'>Download the Token Miner (Windows)</div>
        </a>
      <div class=" "></div>
        <a href="https://github.com/lwYeo/SoliditySHA3Miner/releases" target="_blank">
          <div class='bg-purple-500 p-4 mt-4 rounded text-black inline-block  hover:bg-purple-400'>Download the Token Miner (Linux)</div>
        </a>

     <div class="whitespace-sm"></div>
     <br>

     <div class="whitespace-sm"></div>


       

    </section>




    <section class="flex flex-row hidden ">

        <div class="w-1/2">
            <div class="hidden">
            <HashrateChart

            />
            </div>
      </div>
      <div class="w-1/2">
         
            <div class="card card-background-secondary"  >
              <div class="card-content text-white ">


                
                  <p>Minting:
                     <a v-cloak v-bind:href='poolAPIData.etherscanMintingURL' >
                       {{poolAPIData.mintingAddress}}
                       </a> 
                  </p>
                
                <p>Payments:   
                 <a v-cloak v-bind:href='poolAPIData.etherscanPaymentsURL' >
                  {{poolAPIData.paymentsAddress}}
                </a>
                </p>



              </div>
          </div>

       </div>

    </section>
     
    
  


     </div>
   </div>

    

 



  <Footer/>

</div>
</template>


<script>
import Navbar from './components/Navbar.vue'; 
import Footer from './components/Footer.vue';
import Moralis from './plugins/moralis'

import HashrateChart from './components/HashrateChart.vue';

import SocketHelper from '../js/socket-helper'

export default {
  name: 'Home',
  props: [],
  components: {Navbar,HashrateChart,Footer},
  data() {
    return {
      
      poolAPIData: {}, //read this from sockets
      poolStatus: null,
      miningAccountSearchQuery: null, 
      web3Plug: null,
      showInstructions: false,
      showFAQ: false,
      validateAddress: false


    }
  },
  created(){
      this.socketHelper = new SocketHelper()
      
      setInterval(this.pollSockets.bind(this),5000)


      this.socketsListener = this.socketHelper.initSocket()
     
     
      this.socketsListener.on('poolData', (data) => {  
             
            this.poolAPIData = data 
        });

          this.socketsListener.on('poolStatus', (data) => {   
            this.poolStatus = data

        });


         this.socketsListener.on('recentSolutions', (data) => {  
            this.recentSolutionTx=data
        });

         this.socketsListener.on('recentPayments', (data) => {  
            this.recentPaymentTx=data
        });

        this.pollSockets()
  },
  watch:{
      miningAccountSearchQuery: function(val){
        const web3 = new Moralis.Web3();
        console.log(web3.utils.isAddress(val));
        if(web3.utils.isAddress(val) == false){
          this.validateAddress = true;
        }else{
           this.validateAddress = false;
        }
      }
  },
  methods: {
    submitMiningAccountSearch( ){  
      if(this.validateAddress ==false)
        this.$router.push('profile/'+this.miningAccountSearchQuery.toString().toLowerCase().substr(0, 42) );
    //  console.log('submitMiningAccountSearch ', this.miningAccountSearchQuery)
    },

    pollSockets(){
      this.socketHelper.emitEvent('getPoolData')
       this.socketHelper.emitEvent('getPoolStatus')
      
    },
    // Method to format date
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, options);
    }
  }
}
</script>
<style scoped>
.invalid{
  border: 2px solid red;
}
.errormsg{
  color: red;
}
</style>
